import React from 'react';

const HomePage = () => {
    return (
        <div className="container">
            <h2 className="text-center mt-5">Добро пожаловать на главную страницу!</h2>
        </div>
    );
};

export default HomePage;
