import React from 'react';
import LoginForm from '../components/LoginForm';

const LoginPage = ({ onLogin }) => {
    return (
        <div className="container mt-4">
            <LoginForm onLogin={onLogin}/>
        </div>
    );
};

export default LoginPage;
