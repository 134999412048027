import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import EditNews from './EditNews';

const NewsList = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editId, setEditId] = useState(null); // Состояние для отслеживания id новости для редактирования
    const [showModal, setShowModal] = useState(false); // Состояние для открытия/закрытия модального окна

    const fetchNews = async () => {
        try {
            const response = await fetch('https://yatax.online:3020/api/news/');
            if (!response.ok) {
                throw new Error('Failed to fetch news');
            }
            const data = await response.json();
            setNews(data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching news:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews();
    }, []);

    const handleEdit = (id) => {
        setEditId(id); // Устанавливаем id новости для редактирования
        setShowModal(true); // Открываем модальное окно при редактировании новости
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://yatax.online:3020/api/news/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete news');
            }

            // Обновляем список новостей после удаления
            const updatedNews = news.filter(item => item.id !== id);
            setNews(updatedNews);
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        } else {
            return text;
        }
    };

    const handleCloseModal = () => {
        setShowModal(false); // Закрываем модальное окно
        setEditId(null); // Сбрасываем id новости при закрытии модального окна
    };

    const handleUpdateNews = () => {
        // Обновляем список новостей после редактирования
        fetchNews();
        setShowModal(false); // Закрываем модальное окно после успешного редактирования
        setEditId(null); // Сбрасываем id новости после успешного редактирования
    };

    if (loading) {
        return <p>Loading...</p>; // Прелоадер, пока данные загружаются
    }

    return (
        <div className="container">
            <h2 className="mt-3 mb-3">Новости</h2>
            <div className="row">
                {news.map((item) => (
                    <div key={item.id} className="col-md-4 mb-4">
                        <div className="card">
                            <img src={`${item.image}`} className="card-img-top" alt={item.title}/>
                            <div className="card-body">
                                <h5 className="card-title">{item.title}</h5>
                                <p className="card-text">{truncateText(item.content, 100)}</p>

                                <Link to={`/news/${item.id}`} className="btn btn-primary mr-2">Подробнее</Link>
                                <button onClick={() => handleEdit(item.id)} className="btn btn-warning mr-2">Редактировать</button>
                                <button onClick={() => handleDelete(item.id)} className="btn btn-danger">Удалить</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {editId && (
                <EditNews
                    id={editId}
                    onClose={handleCloseModal}
                    onUpdate={handleUpdateNews}
                />
            )}
        </div>
    );
};

export default NewsList;
