import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const EditNews = ({ id, onClose, onUpdate }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [previewImage, setPreviewImage] = useState(null); // Стейт для отображения превью нового изображения

    useEffect(() => {
        const fetchNewsDetails = async () => {
            try {
                const response = await fetch(`https://yatax.online:3020/api/news/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch news details');
                }
                const data = await response.json();
                setTitle(data.title);
                setContent(data.content);
                setCurrentImage(data.image || null);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching news details:', error);
                setLoading(false);
            }
        };

        fetchNewsDetails();
    }, [id]);

    // Обновление превью нового изображения при выборе файла
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setPreviewImage(null);

        }
    };

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            if (image) {
                formData.append('image', image);
            }

            const token = localStorage.getItem('token');
            const response = await fetch(`https://yatax.online:3020/api/news/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${token}`,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to update news');
            }

            onUpdate();
            onClose();
        } catch (error) {
            console.error('Error updating news:', error);
        }
    };

    const handleRemoveImage = async () => {
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('image', ''); // Передаем пустое значение для удаления изображения

            const token = localStorage.getItem('token');
            const response = await fetch(`https://yatax.online:3020/api/news/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${token}`,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to remove image');
            }

            setCurrentImage(null);
            setImage(null);
            setPreviewImage(null); // Убираем превью удаленного изображения
            onUpdate();
            onClose();
        } catch (error) {
            console.error('Error removing image:', error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Редактировать новость</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSave}>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Заголовок</label>
                        <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="content" className="form-label">Содержание</label>
                        <textarea className="form-control" id="content" value={content} onChange={(e) => setContent(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="image" className="form-label">Изображение</label>
                        {previewImage ? (
                            <div className="mb-2">
                                <img src={previewImage} className="img-fluid mb-2" alt="Preview" />
                            </div>
                        ) : (
                            currentImage && (
                                <div className="mb-2">
                                    <img src={`https://yatax.online:3020/public/files/news/${currentImage}`} className="img-fluid mb-2" alt="Current news" />
                                    {/* <Button variant="danger" onClick={handleRemoveImage}>Удалить изображение</Button> */}
                                </div>
                            )
                        )}

                        <input type="file" className="form-control" id="image" onChange={handleImageChange} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditNews;
