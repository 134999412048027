import React from 'react';
import {Link} from "react-router-dom";

const ParticipantList = ({ participants }) => {

    return (
        <div className="row">
            {participants.map(participant => (
                <div className="col-md-4 mb-3" key={participant.id}>
                    <div className="card">
                        <div className="card-body">
                            {participant.avatar ? (
                                <img
                                    src={`https://yatax.online:3020/public/avatar/${participant.avatar}`}
                                    alt="Avatar Preview"
                                    className="avatar-mob mb-2 text-center m-auto d-flex justify-content-center"
                                />
                            ) : (
                                <div className="avatar-placeholder">
                                    <img
                                        src="https://static-00.iconduck.com/assets.00/user-circle-icon-512x512-6zuyfa08.png"
                                        alt="Avatar Placeholder"
                                        className="avatar-mob mb-2 text-center m-auto d-flex justify-content-center"
                                    />
                                </div>
                            )}
                            <h5 className="card-title">ID: {participant.id}</h5>
                            <h5 className="card-title">{participant.name}</h5>
                            <p className="card-text">Email: {participant.email}</p>
                            <h5 className="card-title">Активация: {participant.isActive == true ? 'Активироан' :'Не активирован'} </h5>
                            <p className="card-text">Telegram: {participant.telegram}</p>
                            <p className="card-text">
                                Зарегестрировано: {new Date(participant.createdAt).toLocaleDateString('ru-RU', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })} в {new Date(participant.createdAt).toLocaleTimeString('ru-RU', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                            </p>
                            <Link to={`/admin/users/edit/${participant.id}`} className="btn btn-primary">
                                Редактировать профиль
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ParticipantList;
