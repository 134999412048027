import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ActivationModal = ({ show, handleClose, handleResend }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Активировать аккаунт</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Ваш аккаунт не активирован. Пожалуйста, активируйте его по ссылке, отправленной на ваш email.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleResend}>
                    Отправить письмо повторно
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActivationModal;
