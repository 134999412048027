import React from 'react';
import {Link} from 'react-router-dom';

const AdminPage = () => {
    return (
        <div className="container mt-5 ">
            <h1 className="text-center">Админка</h1>
            <Link to="/admin/users" className="btn btn-primary m-1">
                Все пользователи
            </Link>
            <Link to="/admin/news/add" className="btn btn-primary">
                Добавить новость
            </Link>
        </div>
    )
}

export default AdminPage;

