import React, { useState, useEffect,  } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from "./pages/RegisterPage";
import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/Profile/Settings";
import AdminPage from "./pages/AdminPage";
import UserPage from "./pages/admin/UserPage";
import EditUser from "./pages/admin/EditUser";

import  './App.css'

import NewsList from "./pages/News/News";
import NewsDetails from "./pages/News/NewsDetails";
import AddNews from "./pages/News/AddNews";
import RequestPasswordReset from "./pages/ResetPassword";
import ResetPassword from "./pages/ResetPasswordSend";
import ActivationModal from "./components/ActivationModal";
import ActivateAccount from "./components/ActivateAccount";
import BalancePage from "./pages/Balance/BallancePage";

function App() {
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [userData, setUserData] = useState(null);
    const [showActivationModal, setShowActivationModal] = useState(false);

    useEffect(() => {
        if (token) {
            fetchUserData(token);
        }
    }, [token]);

    const fetchUserData = async (token) => {
        try {
            const response = await fetch('https://yatax.online:3020/api/users/me', {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Не удалось получить данные пользователя');
            }

            const data = await response.json();
            setUserData(data);
            if (!data.isActive) {
                setShowActivationModal(true);
            }
        } catch (error) {
            console.error('Ошибка:', error);
            handleLogout();
        }
    };

    const handleLogin = (newToken) => {
        setToken(newToken);
        localStorage.setItem('token', newToken);
        fetchUserData(newToken);
    };


    const handleLogout = () => {
        setToken(null);
        setUserData(null);
        localStorage.removeItem('token');
        return <Navigate to="/" />;
    };


    const handleResendActivationEmail = async () => {
        try {
            const response = await fetch('https://yatax.online:3020/api/auth/resend-activation-email', {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Не удалось отправить письмо активации');
            }

            const data = await response.json();
            alert(data.message);
        } catch (error) {
            console.error('Ошибка при отправке письма активации:', error);
            alert('Ошибка при отправке письма активации');
        }
    };
    const isAuthenticated = !!token;
    const isActive = userData && userData.isActive;
    return (
        <Router>
            <div>
                <Header isAuthenticated={isAuthenticated} userData={userData} onLogout={handleLogout} />
                <main>
                    <Routes>
                        {isActive && isAuthenticated ? (
                            <>
                                <Route path="/profile" element={<DashboardPage userData={userData} />} />
                                <Route path="/settings" element={<SettingsPage userData={userData} />} />
                                <Route path="/admin" element={<AdminPage userData={userData} />} />
                                <Route path="/admin/users" element={<UserPage />} />
                                <Route path="/admin/users/edit/:id" element={<EditUser />} />
                                <Route path="/admin/news/add/" element={<AddNews />} />
                                <Route path="/news" element={<NewsList />} />
                                <Route path="/news/:id" element={<NewsDetails />} />
                                <Route path="/balance/" element={<BalancePage />} />

                            </>
                        ) : (
                            <>

                                <Route path="/login" element={isAuthenticated ? <Navigate to="/profile" /> : <LoginPage onLogin={handleLogin} />} />
                                <Route path="/register" element={isAuthenticated ? <Navigate to="/profile" /> : <RegisterPage />} />
                                <Route path="/request-password-reset" element={<RequestPasswordReset />} />
                                <Route path="/reset-password/:token" element={<ResetPassword />} />

                            </>

                        )}
                        <Route path="/" element={isAuthenticated ?  <HomePage /> : <HomePage />} />
                        <Route path="/activate/:token" element={<ActivateAccount />} />
                    </Routes>
                    <ActivationModal
                        show={showActivationModal}
                        handleClose={() => setShowActivationModal(false)}
                        handleResend={handleResendActivationEmail}
                    />
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
