import React, { useState } from 'react';
import { useParams, useNavigate} from 'react-router-dom';

const ResetPassword = () => {
    const { token } = useParams();
    const history = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError('Пароли не совпадают');
            return;
        }

        try {
            const response = await fetch('https://yatax.online:3020/api/auth/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token, newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                setError('');
                setTimeout(() => {
                    history('/login');
                }, 2000);
            } else {
                setError(data.error);
                setMessage('');
            }
        } catch (err) {
            setError('Ошибка при сбросе пароля');
            setMessage('');
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Сброс пароля</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">Новый пароль:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Подтвердите новый пароль:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Сбросить пароль</button>
            </form>
            {message && <div className="alert alert-success mt-3">{message}</div>}
            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
    );
};

export default ResetPassword;
