import React, { useState } from 'react';

const RegisterForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        telegram: ''
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage(null);

        try {
            const response = await fetch('https://yatax.online:3020/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Не удалось зарегистрироваться');
            }

            setSuccessMessage('Вы успешно зарегистрировались. Теперь авторизуйтесь.');
            setFormData({
                name: '',
                email: '',
                password: '',
                telegram: ''
            });

        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm mx-auto mt-8">
            {error && <p className="text-danger">{error}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Имя:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Пароль:</label>
                <input type="password" name="password" value={formData.password} onChange={handleChange} required className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="telegram" className="form-label">Telegram:</label>
                <input type="text" name="telegram" value={formData.telegram} onChange={handleChange} className="form-control" />
            </div>
            <button type="submit" className="btn btn-primary w-100">Зарегистрироваться</button>
        </form>
    );
};

export default RegisterForm;

