import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom

const AddNews = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null); // Стейт для отображения превью нового изображения
    const history = useNavigate(); // Подключаем useHistory для программного перехода

    // Обновление превью нового изображения при выборе файла
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setPreviewImage(null);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault(); // Предотвращаем стандартное поведение отправки формы

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            if (image) {
                formData.append('image', image);
            }

            const token = localStorage.getItem('token');
            const response = await fetch('https://yatax.online:3020/api/news', {
                method: 'POST',
                headers: {
                    'Authorization': `${token}`, // Исправленный заголовок для токена
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            // Очищаем поля формы и превью изображения
            setTitle('');
            setContent('');
            setImage(null);
            setPreviewImage(null);

            // После успешного добавления перенаправляем на страницу с созданной новостью
            history('/news'); // Замените '/news' на ваш путь страницы с новостями
        } catch (error) {
            console.error('Error adding news:', error);
        }
    };

    return (
        <div>
            <h2>Добавить новость</h2>
            <form onSubmit={handleSave}>
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Заголовок</label>
                    <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="content" className="form-label">Содержание</label>
                    <textarea className="form-control" id="content" value={content} onChange={(e) => setContent(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="image" className="form-label">Изображение</label>
                    {previewImage && (
                        <div className="mb-2">
                            <img src={previewImage} className="img-fluid mb-2" alt="Preview" />
                        </div>
                    )}
                    <input type="file" className="form-control" id="image" onChange={handleImageChange} />
                </div>
                <Button variant="primary" type="submit">
                    Добавить
                </Button>
            </form>
        </div>
    );
};

export default AddNews;
