import React from 'react';

import RegisterForm from "../components/RegisterForm";

const RegisterPage = ({ onLogin }) => {
    return (
        <div className="container mt-4">
            <h2 className="text-center">Регистрация</h2>
            <RegisterForm/>
        </div>
    );
};

export default RegisterPage;