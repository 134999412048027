import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-dark text-light p-4 text-center mt-5">
            <div className="container">
                <p>&copy; 2024 OneMedia. Все права защищены.</p>
            </div>
        </footer>
    );
};

export default Footer;

