import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EditProfile = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telegram: '',
    });
    const [avatarFile, setAvatarFile] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://yatax.online:3020/api/users/profile/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user profile');
                }
                return response.json();
            })
            .then(data => {
                setFormData({
                    name: data.name,
                    email: data.email,
                    telegram: data.telegram,
                });
                setLoading(false);
                if (data.avatar) {
                    setAvatarPreview(`https://yatax.online:3020/public/avatar/${data.avatar}`);
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        if (avatarFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarPreview(reader.result);
            };
            reader.readAsDataURL(avatarFile);
        } else {
            setAvatarPreview('');
        }
    }, [avatarFile]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setAvatarFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('telegram', formData.telegram);
        if (avatarFile) {
            formDataToSend.append('avatar', avatarFile);
        }

        try {
            const response = await fetch(`https://yatax.online:3020/api/users/profile/${id}`, {
                method: 'PUT',
                body: formDataToSend,
            });
            if (!response.ok) {
                throw new Error('Ошибка при обновлении профиля');
            }
            setNotification('Профиль успешно обновлен');
        } catch (error) {
            setError(error);
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await fetch(`https://yatax.online:3020/api/users/profile/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Ошибка при удалении аккаунта');
            }
            const data = await response.json();
            setNotification(data.message);

            setTimeout(() => {
                navigate('/admin/users');
            }, 3000);
        } catch (error) {
            setError(error);
        }
    };

    if (loading) return <p>Загрузка...</p>;
    if (error) return <p className="alert alert-danger">Ошибка: {error.message}</p>;

    return (
        <div className="container mt-5">
            <h1 className="text-center">Редактировать профиль</h1>
            {notification && <p className="alert alert-success">{notification}</p>}

            <div className="text-center mb-3">
                <div className="avatar-container" onClick={() => document.getElementById('avatarInput').click()}>
                    {avatarPreview ? (
                        <img
                            src={avatarPreview}
                            alt="Avatar Preview"
                            className="avatar"
                        />
                    ) : (
                        <div className="avatar-placeholder">
                            <img
                                src="https://static-00.iconduck.com/assets.00/user-circle-icon-512x512-6zuyfa08.png"
                                alt="Avatar Placeholder"
                                className="avatar"
                            />
                        </div>
                    )}
                    <input
                        type="file"
                        id="avatarInput"
                        accept="image/*"
                        className="hidden"
                        onChange={handleAvatarChange}
                    />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Имя</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="telegram">Telegram</label>
                    <input
                        type="text"
                        className="form-control"
                        id="telegram"
                        name="telegram"
                        value={formData.telegram}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary mt-3">Сохранить</button>
            </form>

            <button type="button" className="btn btn-danger mt-3" onClick={handleDeleteAccount}>
                Удалить аккаунт
            </button>
        </div>
    );
};

export default EditProfile;

