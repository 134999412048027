import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const NewsDetails = () => {
    const { id } = useParams();
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsDetails = async () => {
            try {
                const response = await fetch(`https://yatax.online:3020/api/news/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch news details');
                }
                const data = await response.json();
                setNews(data);
            } catch (error) {
                console.error('Error fetching news details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!news) {
        return <div>Новость не найдена или не существует.</div>;
    }

    return (
        <div className="container">
            <h2 className="mt-3">{news.title}</h2>
            {news.image && (
                <img src={`https://yatax.online:3020/public/files/news/${news.image}`} className="img-fluid mt-3" alt={news.title} />
            )}
            <div className="mt-3" dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
    );
};

export default NewsDetails;
