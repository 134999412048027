import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ isAuthenticated, userData, onLogout }) => {

    return (
        <header className="bg-dark text-light p-4">
            <div className="container">
                <nav className="d-flex justify-content-between align-items-center">
                    {isAuthenticated && userData && (
                        <div className="nav-item">
                            {userData.avatar ? (
                                <img
                                    src={`https://yatax.online:3020/public/avatar/${userData.avatar}`}
                                    alt="Avatar Preview"
                                    className="avatar-mob"
                                />
                            ) : (
                                <div className="avatar-placeholder">
                                    <img
                                        src="https://static-00.iconduck.com/assets.00/user-circle-icon-512x512-6zuyfa08.png"
                                        alt="Avatar Placeholder"
                                        className="avatar-mob"
                                    />
                                </div>
                            )}
                            {userData.email}
                        </div>
                    )}

                    <ul className="nav">
                        {isAuthenticated ? (
                            <>
                                <li className="nav-item"><Link to="/profile" className="nav-link">Профиль</Link></li>
                                <li className="nav-item"><Link to="/settings" className="nav-link">Настройки</Link></li>
                                <li className="nav-item"><Link to="/admin" className="nav-link">Админка</Link></li>
                                <li className="nav-item">
                                    <button onClick={onLogout} className="btn btn-link nav-link">Выход</button>
                                </li>
                            </>
                        ) : (
                            <>
                            <li className="nav-item"><Link to="/" className="nav-link">Главная</Link></li>
                                <li className="nav-item"><Link to="/login" className="nav-link">Вход</Link></li>
                                <li className="nav-item"><Link to="/register" className="nav-link">Регистрация</Link></li>
                            </>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
