import React, { useState, useEffect } from 'react';
import ParticipantList from "../../components/admin/ParticipantList";


const UserPage = () => {
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        fetchParticipants();
    }, []);

    const fetchParticipants = async () => {
        try {
            const response = await fetch('https://yatax.online:3020/api/users/');
            if (!response.ok) {
                throw new Error('Failed to fetch participants');
            }
            const data = await response.json();
            setParticipants(data);
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h1>Админка</h1>
            <p>Все пользователи</p>
            <ParticipantList participants={participants} />
        </div>
    );
};

export default UserPage;

