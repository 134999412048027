import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap'; // Используем Bootstrap для стилизации уведомлений

const ActivationPage = () => {
    const { token } = useParams(); // Получаем токен из URL
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showActivationModal, setShowActivationModal] = useState(false);

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await fetch(`https://yatax.online:3020/api/auth/activate/${token}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const data = await response.json();
                if (response.ok) {
                    setMessage(data.message);
                    setShowActivationModal(false)
                } else {
                    setError(data.error);
                    setShowActivationModal(false)
                }
            } catch (error) {
                setError('Ошибка при активации аккаунта');
            }
        };

        activateAccount();
    }, [token]);

    return (
        <div className="container mt-4">
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
        </div>
    );
};

export default ActivationPage;
