import React from 'react';
import {Link} from 'react-router-dom';

const BalancePage = () => {
    return (
        <div className="container mt-5 ">
            <h1 className="text-center">Финансы</h1>
            <Link to="/" className="btn btn-primary">
                Добавить кошелек
            </Link>
        </div>
    )
}

export default BalancePage;
