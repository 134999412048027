import React from 'react';
import {Link} from "react-router-dom";

const DashboardPage = ({ userData }) => {

    console.log(userData);
    if (!userData) {
        return <div>Loading...</div>;
    }


    return (
        <div className="container">
            <h2 className="text-center mt-5">Добро пожаловать в кабинет, {userData.name}!</h2>
            <Link to="/news" className="btn btn-primary mx-2">
               Новости
            </Link>
            <Link to="/balance" className="btn btn-primary">
                Финансы
            </Link>
        </div>
    );
};

export default DashboardPage;
